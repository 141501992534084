<template>
  <AlteracaoPasswordRecuperacao />
</template>

<script>
import AlteracaoPasswordRecuperacao from "@/components/users/AlteracaoPasswordRecuperacao.vue";

export default {
  components: {
    AlteracaoPasswordRecuperacao
  }
};
</script>
